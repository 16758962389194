<script setup lang="ts">
const { t } = useI18n()
const runtimeConfig = useRuntimeConfig()

const companyData = runtimeConfig.public.company
</script>

<template>
  <div class="fr">
    <div class="flex items-end sm:items-start">
      <div class="w-full flex flex-col items-center sm:items-end space-y-4">
        <div class="max-w-sm w-full">
          <div class="flex items-start">
            <div class="h-16 w-16 flex flex-shrink-0 items-center justify-center rounded-md bg-slate-100">
              <NIcon icon="i-hugeicons:building-05" class="font-xl block h-8 w-8" />
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-semibold">
                {{ companyData.name }}
              </p>
              <p class="mt-1 text-xs">
                IČO: {{ companyData.id }}<br>
                DIČ: {{ companyData.vatId }}<br>
                <span class="mt-2 block font-semibold">{{ t('footer.company_address.title') }}:</span>
                {{ companyData.streetLine1 }}<br>
                {{ companyData.postalCode }} {{ companyData.city }}
                <span class="mt-2 block font-semibold">{{ t('footer.bank_connection.title') }}:</span>
                {{ t('footer.account_number.title') }}:&nbsp;{{ companyData.bankAccountNumber }}<br>
                {{ t('footer.iban.title') }}:&nbsp;{{ companyData.iban }}<br>
                {{ t('footer.data_box.title') }}:&nbsp;{{ companyData.databox }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sl">
    <div class="flex items-end sm:items-start">
      <div class="w-full flex flex-col items-center sm:items-end space-y-4">
        <div class="max-w-sm w-full">
          <div class="flex items-start items-center">
            <div class="h-16 w-16 flex flex-shrink-0 items-center justify-center rounded-md bg-slate-100">
              <NIcon icon="i-hugeicons:mail-01" class="font-xl block h-8 w-8" />
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-semibold">
                {{ t('footer.customer_support_operating_hours.title') }}
              </p>
              <p class="mt-1 text-xs">
                {{ companyData.emailAddress }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tl">
    <div class="flex items-end sm:items-start">
      <div class="w-full flex flex-col items-center sm:items-end space-y-4">
        <div class="max-w-sm w-full">
          <div class="flex items-start items-center">
            <div class="h-16 w-16 flex flex-shrink-0 items-center justify-center rounded-md bg-slate-100">
              <NIcon icon="i-hugeicons:customer-support" class="font-xl block h-8 w-8" />
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="font-bold font-semibold">
                {{ t('footer.phone_number.title') }}
              </p>
              <p class="mt-1 text-xs">
                <a :href="`tel:${companyData.phoneNumber}`">
                  {{ companyData.phoneNumber }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
ona
