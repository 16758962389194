<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'
import type { Ref } from 'vue'
import { useReviewsState } from '~/composables/reviews'
import CompanyInfo from '~/components/templates/CompanyInfo.vue'

const LEGAL_PAGES_PATH_PREFIX = '/legal'

const { t, locale, locales } = useI18n()
const socials = useSocials()

const localeObject = computed(() => (locales as Ref<LocaleObject[]>).value.find(l => l.code === locale.value))

const [
  resultSubPages,
] = await Promise.all([
  fetchSubPages(),
])
const reviewsState = useReviewsState()
const { data: subPages, loading: subPagesLoading } = toRefs(resultSubPages)

const [
  { data: reviews },
  { data: navigation },
] = await Promise.all([
  useAsyncData('reviews', () => $fetch('/api/reviews', {
    query: { languageCode: locale.value, currencyCode: localeObject.value?.currency },
    onRequest(ctx) {
      ctx.options.headers = { ...ctx.options.headers, 'Content-Language': locale.value, 'Content-Currency': localeObject.value?.currency }
    },
  }).then((result) => {
    reviewsState.value = result.data

    return result.data
  })),
  useAsyncData('navigation', () => fetchContentNavigation({ where: [{ _locale: locale.value }] }), {
    server: false,
    transform: (data) => {
      let nav = data?.map(nav => ({
        ...nav,
        title: t(`page.${nav.title.toLowerCase().replace(/ /g, '_')}.title`),
      })).filter(nav => !nav._path.includes(LEGAL_PAGES_PATH_PREFIX))

      let usefulLinksNavigation = nav.find(nav => nav._path === '/useful-links')

      if (usefulLinksNavigation) {
        usefulLinksNavigation = { ...usefulLinksNavigation }
        const children = usefulLinksNavigation.children ?? []

        if (!children.some(child => child._path === '/account'))
          children.unshift({ _path: '/account', title: t('page.account.title') })

        usefulLinksNavigation.children = children
        nav = nav.map(item => item._path === usefulLinksNavigation?._path ? usefulLinksNavigation : item)
      }

      const legal = data.find(nav => nav._path.includes(LEGAL_PAGES_PATH_PREFIX))?.children ?? []

      return { footer: nav, legal }
    },
  }),
])
</script>

<template>
  <div class="mx-auto max-w-7xl px4 py6 lg:px8 lg:py12 sm:px6">
    <Footer
      :columns="4"
      :open="[t('general.company.label')]"
      class="mx-auto max-w-7xl md:n-border-200 md:border-t px4 py6 lg:px8 lg:py12 sm:px6"
    >
      <template #columns>
        <FooterColumn :title="t('form.company.label')" :default-open="true">
          <CompanyInfo />
        </FooterColumn>
        <template v-for="subPage in subPages" :key="subPage.id">
          <FooterColumn v-if="subPage.pages.some((sp) => sp.show)" :title="subPage.name">
            <template v-for="showInSubPage in subPage.pages">
              <li v-if="showInSubPage.show" :key="showInSubPage.id" :title="showInSubPage.page.name" class="!m-0.5">
                <NuxtLink :to="localePath(`/pages/${showInSubPage.page.slug}`)" class="text-base">
                  {{ showInSubPage.page.name }}
                </NuxtLink>
              </li>
            </template>
          </FooterColumn>
        </template>
      </template>
    </Footer>
  </div>
  <div class="mx-auto max-w-7xl overflow-hidden border-t n-border-200 pb6 pt6">
    <div class="flex justify-between">
      <div>
        &copy; {{ new Date().getFullYear() }} {{ $config.public.company.name }}, {{ t('general.all_rights_reserved.label') }}
      </div>
      <div class="flex gap-x-1">
        <div v-for="social in socials" :key="social.label">
          <NLink :href="social.href" n="slate4 hover:slate5" target="_blank">
            <span class="sr-only" v-text="social.label" />
            <NIcon :icon="social.icon" n="xl" />
          </NLink>
        </div>
      </div>
    </div>
  </div>
</template>
