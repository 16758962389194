<script setup lang="ts">
import TrustableCarousel from '~/components/content/TrustableCarousel.vue'
import BeforeFooter from '~/components/content/BeforeFooter.vue'

const {
  locale,
  t,
} = useI18n()

const route = useRoute()

const days = computed(() => {
  const {
    0: monday,
    4: friday,
  } = getWeekdays(locale.value, 'long')
  return `${monday} - ${friday}`
})
const hours = computed(() => {
  const {
    6: six,
    18: eighteen,
  } = getHours(locale.value)
  return `${six} - ${eighteen}`
})
const isCheckoutPage = computed(() => ((route?.name as string) ?? '').includes('checkout'))
</script>

<template>
  <div class="medium n-top-bar font-m relative z50 block hidden bg-red-700 py1 text-white lg:block">
    <div class="mx-auto h-7 max-w-[83rem] flex items-center justify-center">
      <span class="h-10 min-w-sm w-auto w-fit font-medium" n="sm">
        <TrustableCarousel
          child-class="min-w-0  text-white fw600 uppercase"
          wrapper-class="absolute inset-0 flex items-center px2 overflow-hidden"
        />
      </span>
    </div>
  </div>
  <div class="h10 bg-black lg:hidden">
    <TrustableCarousel
      child-class="min-w-0 flex items-center justify-center text-white fw600 uppercase"
      wrapper-class="absolute inset-0 flex items-center justify-center"
    />
  </div>

  <LayoutHeader />

  <slot />

  <LazyNavDrawer />
  <LazyCartDrawer />

  <div class="flex-1" />

  <ContentGuard>
    <div v-if="!isCheckoutPage" class="mb6 bg-slate-50">
      <div class="mx-auto max-w-[88rem] py8 lg:px4 sm:px2 sm:py14">
        <BeforeFooter />
      </div>
    </div>
  </ContentGuard>
  <!-- Footer -->
  <LayoutFooter />
</template>
