import { upperFirst } from 'scule'

// @unocss-include
const SOCIALS_ICONS = {
  facebook: 'i-mdi:facebook',
  instagram: 'i-mdi:instagram',
  twitter: 'i-mdi:twitter',
  youtube: 'i-mdi:youtube',
}

const SOCIALS_BASE_URLS = {
  facebook: 'https://www.facebook.com/',
  instagram: 'https://www.instagram.com/',
  twitter: 'https://www.twitter.com/',
  youtube: 'https://www.youtube.com/',
}

export function useSocials() {
  return computed(() => Object.entries(useRuntimeConfig().public.socials)
    .filter(([_key, value]) => value !== null && value !== '')
  .map(([key, value]) => ({
    label: upperFirst(key),
      icon: SOCIALS_ICONS[key as keyof typeof SOCIALS_ICONS],
      href: SOCIALS_BASE_URLS[key as keyof typeof SOCIALS_BASE_URLS] + value,
  })),
  )
}
